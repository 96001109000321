<template>
  <div class="payments-list">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col cols="6" md="3" sm="6">
              <b-form-input v-model="search" placeholder="Поиск" />
            </b-col>
            <b-col class="text-right">
              <b-button v-b-toggle.sidebar-backdrop variant="primary">
                Добавить прямой платеж
              </b-button>
              <b-sidebar
                id="sidebar-backdrop"
                ref="sidebar"
                backdrop
                backdrop-variant="dark"
                right
                shadow
                title="Добавить платеж"
              >
                <deal-add-payment></deal-add-payment>
              </b-sidebar>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <b-overlay :show="isLoading" rounded="sm">
                <b-table
                  id="my-table"
                  :fields="fields"
                  :items="payments"
                  bordered
                  empty-text="Нет данных"
                  hover
                  responsive
                  show-empty
                  @row-clicked="openDeal"
                >
                  <template #cell(distribution_details)="row">
                    <b-button
                      class="mr-2 mb-1"
                      size="sm"
                      @click="row.toggleDetails"
                    >
                      {{ row.detailsShowing ? 'Спрятать' : 'Показать' }}
                      разбивку
                    </b-button>
                    <b-button
                      v-b-modal.add-handle-distribution
                      size="sm"
                      @click="paymentId = row.item.id"
                    >
                      Ручное распределение
                    </b-button>
                  </template>

                  <template #row-details="row">
                    <b-table-lite
                      :fields="subFields"
                      :items="row.item.transactions"
                    >
                    </b-table-lite>
                  </template>
                </b-table>
              </b-overlay>
            </b-col>
          </b-row>
          <add-handle-distribution :id="paymentId"></add-handle-distribution>
          <b-row class="d-flex justify-content-center mt-2">
            <b-pagination
              v-model="currentPage"
              :per-page="50"
              :total-rows="totalCount"
              aria-controls="my-table"
            ></b-pagination>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DealAddPayment from '@/views/deals/components/DealAddPayment.vue'
import AddHandleDistribution from '@/views/deals/components/AddHandleDistribution.vue'

export default {
  // TODO Make payment grid as standalone component
  name: 'DealsList',
  components: { DealAddPayment, AddHandleDistribution },
  data() {
    return {
      payments: [],
      paymentId: 0,
      search: '',
      currentPage: 1,
      isLoading: false,
      totalCount: 0,
      fields: [
        {
          key: 'date',
          label: 'Дата платежа',
        },
        {
          key: 'deal.document_number',
          label: 'Договор',
        },
        {
          key: 'value',
          label: 'Сумма, BYN',
        },

        {
          key: 'source_display',
          label: 'Источник',
        },

        {
          key: 'deal.initials',
          label: 'Имя',
        },
        {
          key: 'deal.phone',
          label: 'Телефон',
        },
        {
          key: 'comment',
          label: 'Комментарий',
        },
        {
          key: 'distribution_details',
          label: 'Разбивка',
        },
      ],
      subFields: [
        {
          key: 'balance_value',
          label: 'Осн. долг',
        },
        {
          key: 'perc_value',
          label: 'Проценты',
        },
        {
          key: 'fine_value',
          label: 'Пеня',
        },
        {
          key: 'late_payment_fine',
          label: 'Штраф',
        },
        {
          key: 'duty_value',
          label: 'Гос. пошлина',
        },
        {
          key: 'comment',
          label: 'Комментарий',
        },
      ],
    }
  },
  watch: {
    currentPage() {
      this.getPayments()
    },
  },
  created() {
    this.getPayments()
  },
  methods: {
    async getPayments() {
      this.isLoading = true
      const res = await this.$http.get(`admin/payments/`, {
        params: { page: this.currentPage, search: this.search },
      })
      this.payments = res.data.results
      this.totalCount = res.data.count
      this.isLoading = false
    },
    openDeal(item) {
      this.$router.push({ name: 'deal-view', params: { id: item.deal.id } })
    },
  },
}
</script>
